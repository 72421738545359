module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Wojtek Jodel', // Navigation and Site Title
  siteTitleAlt: 'Wojtek Jodel - Designer, Developer & Enterpreneur - Portfolio', // Alternative Site title for SEO
  siteHeadline: 'Wojtek Jodel - Designer, Developer & Enterpreneur - Portfolio', // Headline for schema.org JSONLD
  siteTitleShort: 'Wojtek Jodel', // short_name for manifest
  siteUrl: 'https://www.wojtekjodel.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Presentation of my chosen work, including various websites, webapps & apps. I am passionate both about designer & development, and areas where these overlap.',
  author: 'wojtekjodel', // Author for schema.org JSONLD
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // ogSiteName: 'emma', // Facebook Site Name
  userTwitter: '@wojtekjodel', // Twitter Username
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-140805741-1',

  // Manifest and Progress color
  themeColor: '#EE526E',
  backgroundColor: '#2b2e3c',
}
