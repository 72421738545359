import React from 'react'
import PT from 'prop-types'
import { Carousel } from 'react-responsive-carousel'

const ProjectCarousel = ({ screenshots }) => (
  <Carousel
    showArrows
    showStatus={false}
    showIndicators={false}
    emulateTouch
    transitionTime={250}
    useKeyboardArrows
  >
    {screenshots.map(screenshot => (
      <div>
        <img src={screenshot.image} />
        <p className="legend">{screenshot.label}</p>
      </div>
    ))}
  </Carousel>
)

export default ProjectCarousel

ProjectCarousel.propTypes = {
  screenshots: PT.arrayOf(PT.shape({
    image: PT.string.isRequired,
    label: PT.string.isRequired,
  })).isRequired,
}