import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const TagContainer = styled.div`
  display: inline-block;
  background-color: ${props => props.color};
  border-radius: 1rem;
  padding: 0rem 0.5rem 0.2rem;
  margin: 0.3rem 0.3rem 0 0;
`

const TagContent = styled.span`
  font-size: 0.75rem;
  line-height: 0.5rem;
  vertical-align: middle;
  color: ${props => props.color === '#FFFFFF' ? '#000000' : '#FFFFFF'};
`

const Tag = ({ children, color }) => {

  return (
    <TagContainer color={color}>
      <TagContent color={color}>
        {children}
      </TagContent>
    </TagContainer>
  )
}

export default Tag

Tag.propTypes = {
  children: PT.node.isRequired,
  color: PT.string,
}

Tag.defaultProps = {
  color: '#FFFFFF',
}